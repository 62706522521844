a.animated:after {
  content: "";
  display: block;
  border-bottom: 4px solid white;
  background-color: white;
  width: 0;
  transition: 0.5s;
}

a.animated:hover:after {
  width: 100%;
}

a.animated-dark:after {
    content: "";
    display: block;
    border-bottom: 4px solid black;
    background-color: white;
    width: 0;
    transition: 0.5s;
  }
  
  a.animated-dark:hover:after {
    width: 100%;
  }

  li {
    list-style: none;
    padding-left: 2rem;
  }

  li::before {
    content: "\2022";
    color: white;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.hover-text:hover {
  color: white !important;
}
